@import url('https://fonts.googleapis.com/css2?family=Ga+Maamli&display=swap');

/* navbar */

.Nav-logo {
    width: 160px;
    height: auto;
    filter: drop-shadow(5px 5px 5px #fff); 
    background: #fff;
    border-radius: 15px;
    /* padding: 35px; */
  }
  
  .MuiAppBar-colorPrimary {
    color: #fff;
    background-color: #63cf48 !important;
  }
  
  .navbar {
    background-color: #63cf48;
    color: #000;
    /* background: rgb(0,15,38);
  background: linear-gradient(319deg, rgba(0,15,38,1) 0%, rgba(115,160,197,1) 96%, rgba(152,221,237,1) 100%);

  background: rgb(54,156,166);
background: linear-gradient(90deg, rgba(54,156,166,1) 30%, rgba(15,121,168,1) 65%); */

background: #63cf48;
background: linear-gradient(90deg, #63cf48 30%, rgba(15,121,168,1) 65%);

background-image: url('./bg3.png');
  
  }
  
  .each-slide-effect>div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: 350px;
  }
  
  .each-slide-effect span {
    padding: 20px;
    font-size: 20px;
    background: #efefef;
    text-align: center;
  }

  .css-yz9k0d-MuiInputBase-input {
    padding-left: 15px !important;
  }

  .css-1le28he-MuiInputBase-root {
    border-radius: 20px;
    border: 1px solid #000;
    background-color: #fff;
    color: #000 !important;
  }

  .makeStyles-search-4 {
    background-color: transparent !important;
    border-radius: 0px !important;
  }

  .makeStyles-searchIcon-5 {
    padding: 0px 0px !important;
  }

 