/* Footer Styles */
.footer-container {
    /* display: flex; */
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    background-color: #000;
    padding: 20px;
    background: #000000df;
    color: #fff;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
    background-image: url('../components/footer-bg.png');
}

.footer-box1, .footer-box2 {
    /* flex: 1 1 100%; */
    margin: 10px;
    padding: 20px;
    margin: auto;
    text-align: center;
}

.footer-box1 button {
    display: block;
    width: 100%;
    padding: 15px 30px;
    margin: 5px 0;
    text-align: left;
    text-transform: uppercase;
    background-color: #63cf48;
    color: #000;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    /* background-image: url('../components/bg2.jpg'); */
    background-color: #63cf48;
/* background: linear-gradient(90deg, rgba(18,190,211,1) 30%, rgba(15,121,168,1) 65%); */
font-family: 'Ga Maamli';
font-weight: 400;
}

.footer-box1 button:hover {
    background-color: #b2ff8fcc;
  border: 1px solid #63cf48;
  color: #000;
  box-shadow: #b3ff8fb8 0px 5px 15px;
    
}

.footer-box2 {
    text-align: center;
    
}

.footer-box2 p {
    font-family: Arial, sans-serif;
    color: #fff;
    font-size: 16px;
    margin: 10px 0;
}

.footer-box2 .contact-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
}

.footer-box2 .contact-info span {
    display: flex;
    align-items: center;
    margin: 5px 0;
    
}

.footer-icon {
    font-size: 35px;
    margin-right: 5px;
    color: #63cf48;
}

.footer-icon:hover {
    background-color: #b2ff8fcc;
  border: 1px solid #63cf48;
  color: #000;
  box-shadow: #b3ff8fb8 0px 5px 15px;
  border-radius: 8px;
}





/* .footer-box2 .social-icons a {
    display: inline-block;
    margin: 0 5px;
    color: #63cf48;
    font-size: 24px;
    transition: color 0.3s ease;
} */

/* .footer-box2 .social-icons a:hover {
    color: #2980b9;
} */

/* Media Queries */
@media (min-width: 768px) {
    .footer-box1, .footer-box2 {
        flex: 1; /* Equal width for larger screens */
        max-width: 100%; 
    }

    .footer-box1 button, .footer-box2 a {
        display: inline-block;
        width: auto;
        margin: 5px;
        text-decoration: none;
        
    }

    .footer-box2 .contact-info {
        flex-direction: row;
        justify-content: center;
    }
}
