.news-blogs-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    padding: 20px;
    width: 80%;
    margin: auto;
  }
  
  .blog-card {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  }
  
  .blog-card:hover {
    transform: scale(1.03);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
  }
  
  .blog-title {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #333;
  }
  
  .blog-content {
    font-size: 1rem;
    color: #555;
    line-height: 1.6;
  }
  
  /* Media Queries */
  @media (max-width: 768px) {
    .blog-title {
      font-size: 1.25rem;
    }
  
    .blog-content {
      font-size: 0.95rem;
    }
  }
  
  @media (max-width: 480px) {
    .news-blogs-container {
      padding: 10px;
    }
  
    .blog-card {
      padding: 15px;
    }
  
    .blog-title {
      font-size: 1rem;
    }
  
    .blog-content {
      font-size: 0.9rem;
    }
  }
  