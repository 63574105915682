@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Ga+Maamli&display=swap');


/* body{
  background-image: url('./components/bg3.png');
} */

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Bebas Neue", sans-serif;
  color: #000; /* Choose any color that fits your design */
  text-transform: uppercase; /* Makes the text all uppercase */
  margin: 20px 0;
  
}

h2{
  color: #fff;
}

.section h2{
  color: #000;
}

p {
  text-align: justify;
  text-justify: inter-word;
}

/* Default font sizes for larger screens */
h1 {
  font-size: 48px;
  margin: auto;
  text-align: center;
  letter-spacing: 10px;
}

h2 {
  font-size: 36px;
}

h3 {
  font-size: 30px;
  
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 20px;
}

h6 {
  font-size: 16px;
}

/* Media queries for tablets */
@media (max-width: 768px) {
  h1 {
    font-size: 40px;
  }

  .main-heading h1 {
    font-size: 40px;
  }

  h2 {
    font-size: 32px;
  }

  h3 {
    font-size: 28px;
  }

  h4 {
    font-size: 22px;
  }

  h5 {
    font-size: 18px;
  }

  h6 {
    font-size: 16px;
  }
}

/* Media queries for mobile devices */
@media (max-width: 480px) {
  h1 {
    font-size: 32px;
  }

  .main-heading h1 {
    font-size: 32px;
  }

  h2 {
    font-size: 28px;
  }

  h3 {
    font-size: 24px;
  }

  h4 {
    font-size: 20px;
  }

  h5 {
    font-size: 16px;
  }

  h6 {
    font-size: 14px;
  }
}

.flag-icon {
  width: 22px; 
  height: 22px; 
  margin-right: 8px; 
  vertical-align: middle; 
  
}

/* .flag-icon:hover {
  filter: hue-rotate(100deg) brightness(2.8) saturate(100);
} */


.home-1-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 20px;

  /* background: rgb(95,159,150);
background: linear-gradient(90deg, rgba(95,159,150,1) 30%, rgba(15,121,168,1) 65%); */
}

.home-1-box1,
.home-1-box2 {
  flex: 1 1 100%; /* Default to full width */
  margin: 10px;
  /* padding: 20px; */
  margin: auto;
  text-align: center;
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
}

.home-1-box1 img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.home-1-box2 h2 {
  font-family: "Bebas Neue", sans-serif;
  color: #000;
  text-transform: uppercase;
  margin: 10px 0;
  letter-spacing: 1px;
}

.home-1-box2 p {
  font-family: Arial, sans-serif;
  color: #34495e;
  font-size: 16px;
  line-height: 1.5;
}

.features-list {
  text-align: left;
  /* text-align: justify; */
}

/* Media Queries */
@media (min-width: 768px) {
  .home-1-box1,
  .home-1-box2 {
    flex: 1; /* Equal width for larger screens */
    max-width: 45%; /* Adjust to fit within the home-1-container */
  }

  .home-1-container {
    flex-wrap: nowrap;
  }
}

.location-container {
  padding: 20px;
  text-align: center;
}

.map-container {
  margin: 20px auto;
  width: 100%;
  max-width: 600px;
  height: 450px;
  border: 1px solid #ddd;
}

.map-container iframe {
  width: 600px;
  height: 450px;
}

.location-info {
  margin-top: 20px;
}

.location-info p {
  margin: 5px 0;
}

.location-info a {
  color: #63cf48;
  text-decoration: none;
}

.location-info a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .location-container {
    padding: 10px;
  }

  .map-container {
    height: 400px;
  }

  .location-info p,
  .location-info a {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .location-container {
    padding: 5px;
  }

  .map-container iframe {
    width: 300px;
    height: 150px;
  }

  .map-container {
    width: 100%;
    height: 100%;
  }

  .location-info p,
  .location-info a {
    font-size: 12px;
  }
}

/* Classes */

.classes-container {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  /* background: rgb(95,159,150);
background: linear-gradient(90deg, rgba(95,159,150,1) 30%, rgba(15,121,168,1) 65%); */

  /* background: rgb(18,190,211);
background: linear-gradient(90deg, rgba(18,190,211,1) 30%, rgba(15,121,168,1) 65%); */
}

.box {
  flex: 1 1 100%;
  box-sizing: border-box;
  padding: 20px;
  margin: 10px;
  /* border: 1px solid #ddd; */
  margin: auto;
  text-align: center;
  align-items: center;
}

.box2 {
  background: #63cf48;
  background: linear-gradient(
    90deg,
    rgba(18, 190, 211, 1) 30%,
    rgba(15, 121, 168, 1) 65%
  );

  border-radius: 20px;
  text-align: left;
  background-image: url("./components/b2bg.jpg");
  background-size: cover; /* Adjust this as needed */
  background-position: center; /* Adjust this as needed */
}

.box img {
  max-width: 100%;
  height: auto;
  border-radius: 15px;
}

.contact-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  margin-bottom: 5px;
  display: block;
  color: #fff;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-group textarea {
  resize: vertical;
  height: 100px;
}

button[type="submit"] {
  padding: 10px 20px;
  background-color: #63cf48;
  color: #000;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  border-radius: 20px;
  font-family: 'Ga Maamli';
}

button[type="submit"]:hover {
  background-color: #63cf48;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  color: #fff;
}

.classes-box1 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px; /* Adjust the gap between buttons as needed */
  flex-wrap: wrap;
}

.classes-box1 a {
  text-decoration: none;
}

.classes-box1 button {
  padding: 10px 20px;
  font-size: 16px; 
  cursor: pointer;
  border: none;
  background-color: #63cf48; /* Adjust background color as needed */
  color: #000; /* Adjust text color as needed */
  border-radius: 5px; /* Adjust border radius as needed */
  transition: background-color 0.3s;
  width: 150px;
  font-family: 'Ga Maamli';
}

.classes-box1 button:hover {
  background-color: #b3ff8fb8;
  border: 1px solid #63cf48;
  color: #000;
  box-shadow: #b3ff8fb8 0px 5px 15px;
}

.box1 p{
  text-align: left;
}

@media (max-width: 600px) {
  .classes-box1 {
    flex-direction: column;
    gap: 5px; /* Adjust the gap between buttons as needed for small screens */
  }

  .classes-box1 button {
    width: 150px;
  }
}

@media (max-width: 320px) {
  .classes-box1 {
    flex-direction: column;
    gap: 5px; /* Adjust the gap between buttons as needed for small screens */
  }

  .classes-box1 button {
    width: 150px;
  }

  .box1 h1 {
    font-size: 40px;
  }

  

}

.ot-container{
  width: 70%;
  margin: auto;
  align-items: center;
  justify-content: center;
  margin-top: 70px;
  margin-bottom: 70px;
}

.ot-container-img{
  margin: auto;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.ot-container-img img{
  width: 40%;
  margin: auto;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
}

.ot-container h3{
  text-align: center;
}

/* WellMaintained.css */
.ot-container {
  padding: 20px;
  
}

.collage-container {
  width: 80%;
  margin: auto;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: grid;
  /* background-color: red; */
  gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  margin: 20px 0;
  margin-bottom: 50px;
}

.collage-image {
  width: 80%;
  height: auto;
  border-radius: 8px;
  margin: auto;
  text-align: center;
  justify-content: center;
  align-items: center;
}

@media (min-width: 600px) {
  .collage-container {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
}

@media (min-width: 1024px) {
  .collage-container {
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  }
}

.training-program {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #333;
}

@media (min-width: 768px) {
  .box {
    flex: 1;
    max-width: 48%;
  }
}

@media (max-width: 767px) {
  .classes-container {
    flex-direction: column;
  }

  .box {
    max-width: 100%;
    width: 100%;
  }
}

/* contact page  */

.contact-page {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #333;
}

form {
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input,
textarea {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

textarea {
  height: 100px;
}

.contact-info {
  text-align: center;
  margin-top: 20px;
}

/* .contact-info h2 {
    color: #007bff;
  } */

.contact-info p,
.contact-info address {
  margin: 10px 0;
}

.contact-info a {
  color: #007bff;
  text-decoration: none;
}

.contact-info a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .contact-page {
    padding: 10px;
  }

  h1 {
    font-size: 24px;
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 20px;
  }

  .ot-container-img{
    margin: auto;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  
  .ot-container-img img{
    width: 100%;
    margin: auto;
    text-align: center;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
  }
}
